// Fonts
// @import url(https://fonts.googleapis.com/css?family=Raleway:300, 400, 600);

// Variables
//@import "variables";

// Bootstrap
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import 'node_modules/bootstrap-table/src/bootstrap-table';
//@import "./bootstrap-theme";
@import './lumino';

@import './circle';

@import 'node_modules/font-awesome/scss/font-awesome';

table {
	margin-top: 20px;
}

.bootstrap-table .table {
	margin-top: 0 !important;
}

.panel-group {
	position: relative;
}

.box-form-question {
	position: relative;
}

.logo-login-panel {
	margin-bottom: 10px;
}

.sidebar ul.nav .active a,
.sidebar ul.nav li.parent a.active,
.sidebar ul.nav .active > a:hover,
.sidebar ul.nav li.parent a.active:hover,
.sidebar ul.nav .active > a:focus,
.sidebar ul.nav li.parent a.active:focus {
	color: #fff !important;
	background-color: #006491;
}
.sidebar ul.nav a,
.sidebar ul.nav,
.sidebar ul.nav a:hover,
.sidebar ul.nav a:focus {
	color: #006491 !important;
}

.stores-related {
	.well {
		position: relative;
	}
	input[type='checkbox'] {
		display: none;
	}

	input[type='checkbox'] + label {
		color: #666666;
		cursor: pointer;
	}

	input[type='checkbox'] + label span {
		position: absolute;
		display: inline-block;
		width: 12px;
		height: 12px;
		top: 5px;
		left: 5px;
		background-color: #e8e8e8;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		vertical-align: middle;
		cursor: pointer;
		-webkit-border-radius: 30%;
		-moz-border-radius: 30%;
		border-radius: 50%;
	}

	input[type='checkbox']:checked + label span {
		background-color: #3c763d;
		//background:url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/check_radio_sheet.png) -19px top no-repeat;
	}

	input[type='checkbox']:checked + label {
		color: #006491;
	}

	label {
		small {
			font-size: 65%;
			font-weight: 300;
			display: block;
		}
	}
}

.list-stores {
	.panel-body {
		position: relative;
		.loading {
			box-sizing: border-box;
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
}

.navbar-brand img {
	max-width: 150px;
	width: 100%;
	position: relative;
	bottom: 10px !important;
}

// .star_bar {
// 	padding: 15px;
// }

.star_bar i {
	font-size: 25px;
	color: orange;
}

#graphics_row .panel {
	border: 1px solid #ddd;
}

.dashboard-box .fa {
	position: relative;
	bottom: 0px;
	margin-right: 10px;
	font-size: 30px;
}

#boxes .panel {
	box-shadow: none;
}

#boxes .widget-left {
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 35px;
}

#boxes .widget-right {
	line-height: 1.2;
}

#boxes .text-muted {
	margin-top: 5px;
}

.panel-stars {
	padding: 0;
}

.panel-stars > ul {
	margin: 0;
}

.panel-stars > ul.list-group > .list-group-item {
	border: 0;
	border-top: 1px solid #ddd;
}

.stars-resume > span {
	margin-right: 5px;
}

.stars-resume > span.glyphicon {
	font-weight: bold;
	color: orange;
}

.panel-heading a:hover,
.panel-heading a:focus {
	color: black !important;
	text-decoration: none !important;
}

.panel-customize {
	max-height: 450px;
	overflow: auto;
}

.panel-customize::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background-color: #fff;
}

.panel-customize::-webkit-scrollbar {
	width: 8px;
	background-color: #fff;
}

.panel-customize::-webkit-scrollbar-thumb {
	border-radius: 6px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	background-color: #eee;
}

.panel-customize tbody td {
	padding: 13px;
	font-size: 17px;
	border: 0px !important;
}

.panel-heading-icon {
	float: right;
	font-size: 16px;
	position: relative;
	bottom: 36px;
	clear: both;
}

.navbar {
	background-color: #006491 !important;
}

.navbar-toggle {
	display: none;
}

@media screen and (max-width: 1480px) {
	.panel-customize {
		max-height: 300px;
		overflow: auto;
	}
	.panel-customize tbody td {
		font-size: 16px;
	}
}

@media screen and (max-width: 768px) {
	.panel-customize tbody td {
		padding: 8px;
		font-size: 15px;
	}
}

@media screen and (max-width: 580px) {
	.panel-customize thead th {
		font-size: 14px;
	}

	.panel-customize tbody td {
		padding: 8px;
		font-size: 13px;
	}

	.panel-heading-icon {
		float: right;
		font-size: 13px;
		position: relative;
		bottom: 18px;
		clear: both;
	}

	.star_bar i {
		font-size: 20px;
		color: orange;
	}
}

@media screen and (max-width: 480px) {
	.panel-customize thead th {
		font-size: 12px;
	}

	.panel-customize tbody td {
		font-size: 12px;
	}
}

@media screen and (max-width: 380px) {
	.star_bar i {
		font-size: 16px;
		color: orange;
	}
}

#graphics_row .panel-heading {
	height: auto !important;
}

.dashboard-box-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.dashboard-box {
	flex: 1;
	padding: 10px;
	margin-right: 10px;
	min-height: 120px;
	color: white;
	font-size: 20px;
	position: relative;
}

.dashboard-box:nth-child(1) {
	background-color: #f9243f;
}

.dashboard-box:nth-child(2) {
	background-color: #30a5ff;
}

.dashboard-box:nth-child(3) {
	background-color: #ffb53e;
}

.dashboard-box:nth-child(4) {
	background-color: #1ebfae;
}

.dashboard-box:nth-child(5) {
	background-color: #eb7d34;
}

.dashboard-box-top {
	display: flex;
	justify-content: flex-start;
	padding-bottom: 10px;
}

.dashboard-box-bottom {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 10px;
	width: 100%;
	margin-top: 20px;
}

.dashboard-box-bottom span {
	font-size: 30px;
	margin-top: 5px;
}

.dashboard-box-bottom i {
	display: none;
}

@media screen and (max-width: 1280px) {
	.dashboard-box {
		font-size: 20px;
		flex: 1;
	}

	.dashboard-box-top span {
		font-size: 18px;
	}

	.dashboard-box-bottom span {
		font-size: 20px;
		margin-top: 5px;
	}

	.dashboard-box-top i {
		display: none;
	}

	.dashboard-box-bottom i {
		display: block;
	}
}

@media screen and (max-width: 991px) {
	.dashboard-box-row {
		flex-direction: column;
	}

	.dashboard-box {
		font-size: 20px;
		margin: 5px 0;
	}

	.dashboard-box-top span {
		font-size: 18px;
	}

	.dashboard-box-bottom span {
		font-size: 20px;
		margin-top: 5px;
	}

	.dashboard-box-top i {
		display: block;
	}

	.dashboard-box-bottom i {
		display: none;
	}
}

.reports-modal {
	max-width: 400px;
}

.reports-modal .modal-body {
	padding: 0;
	height: 200px;
	overflow-y: scroll;
}

.reports-modal ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.reports-modal li {
	margin: 0;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f5f5f5;
	border: 1px solid #ddd;
	font-size: 18px;
	cursor: pointer;
}

.reports-modal li:hover {
	background-color: #ccc;
	transition: all 0.2s;
}
